@tailwind base;
@tailwind components;
@tailwind utilities;

/* * { border: 1px solid orange !important; } */

@layer base {
  :root {
    --foreground: 0 33 71; /* oxford blue */
    --background: #eaeff2;
    --primary: 0 33 71;
    --secondary: 113 113 122;
    --tertiary: 250 250 250;
    --clippdPink: 254 51 103;
    --border: 244 244 245;
    --nav: 0 0 102;
    --input: 82 82 91;
  }

  .dark {
    --foreground: 230 255 255;
    --background: 0 0 0;
    --primary: 0 33 71;
    --secondary: 113 113 122;
    --tertiary: 250 250 250;
    --clippdPink: 254 51 103;
    --border: 244 244 245;
    --nav: 0 0 102;
    --input: 82 82 91;
  }

  body {
    @apply !block;
  }

  #theme {
    @apply bg-background text-foreground text-sm xl:text-base;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import '~@spikemark/ui/styles/index.css';

.brand-gradient-border {
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-bottom-width: 2px;
  border-image-source: linear-gradient(270deg, rgba(1, 22, 46), rgba(255, 4, 89));
}
